import {useNavigate, useLocation} from 'react-router-dom';
// mui
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
// hooks
import useAuth from 'src/hooks/useAuth';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDate} from 'src/utils/formatTime';
import {fCurrency} from 'src/utils/formatNumber';
import {getInsuranceLabel} from 'src/constants/tab';
import {getClaimTypeLabel} from 'src/constants/dropdown';
// components
import UserAvatar from 'src/components/UserAvatar';
import Label from 'src/components/Label';
// hooks
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const columns = [
    {
      field: 'dateOfLoss',
      headerName: 'Date of Loss',
      width: 160,
      renderCell: (params) => {
        const data = params.row.dateOfLoss;
        return fDate(data);
      }
    },
    {
      field: 'nickName',
      headerName: 'Claim Name',
      width: 160
    },
    {
      field: 'policyNumber',
      headerName: 'Claim number',
      width: 160
    },
    {
      field: 'claimType',
      headerName: 'Claim Type',
      width: 140,
      renderCell: (params) => {
        const type = params.row.claimType;
        return getClaimTypeLabel(type);
      }
    },
    {
      field: 'policyType',
      headerName: 'Policy Type',
      width: 140,
      renderCell: (params) => {
        const type = params.row.policyType;
        return getInsuranceLabel(type);
      }
    },
    {
      field: 'amountPaid',
      headerName: 'Amount Paid',
      width: 140,
      renderCell: (params) => {
        const data = params.row.amountPaid;
        return fCurrency(data);
      }
    },
    {
      field: 'dateClosed',
      headerName: 'Date Closed',
      width: 160,
      renderCell: (params) => {
        const data = params.row.dateClosed;
        return fDate(data);
      }
    },
    {
      field: 'deductibleApplied',
      headerName: 'Deductible Applied',
      width: 140,
      renderCell: (params) => {
        const data = params.row.deductibleApplied;
        return fCurrency(data);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => {
        const status = params.row.status;

        return (
          <Stack sx={{justifyContent: 'center'}}>
            <Label
              variant={isLight ? 'ghost' : 'filled'}
              color={
                status === 'active' || status === 'closed' ? 'success' : status === 'pending' ? 'warning' : 'error'
              }
              sx={{mx: 'auto'}}
            >
              {status}
            </Label>
          </Stack>
        );
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 1
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('claimInsurance');

  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('claimInsurance');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/insurance/claim/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/insurance/claim/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
