import {useNavigate, useLocation} from 'react-router-dom';
// mui
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
// util
import {fCurrency} from 'src/utils/formatNumber';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import UserAvatar from 'src/components/UserAvatar';
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// constants
import {getOrgLabel} from 'src/constants/tab';
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      renderCell: (params) => {
        const name = params?.row?.name ?? params.row.nickName;
        return name;
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 140,
      renderCell: (params) => {
        const type = params.row.type;
        return type === 'trust-estate' ? 'Trust' : getOrgLabel(type);
      }
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 140
    },
    {
      field: 'value',
      type: 'number',
      headerName: 'Value',
      width: 160,
      renderCell: (params) => {
        const price = params.row.value;
        return fCurrency(price);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => {
        const status = params.row.status;
        return <StatusLabel status={status} />;
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 180
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('divestedInsurance');
  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('divestedInsurance');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;
    const type = params.row.type;

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/org/${type}/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/org/${type}/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
