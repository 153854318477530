import React, {useCallback} from 'react';
// mui
import {Checkbox, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
// hook
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

// components
import StatusLabel from 'src/components/_dashboard/insurance/common/StatusLabel';
// ----------------------------------------------------------------------

const RETable = React.memo(({data = [], selectedList = [], handleSelect}) => {
  const common = [
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const dupLevel = params.row.dupLevel;

        return !!dupLevel ? <StatusLabel status={dupLevel} text="duplicated" /> : null;
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 250,
      renderCell: (params) => {
        const address = params.row.address;

        return <Typography>{address?.fullAddress}</Typography>;
      }
    }
  ];
  const columns = !!handleSelect
    ? [
        {
          field: 'select',
          headerName: 'Select',
          width: 70,
          sortable: false,
          filterable: false,
          renderCell: (params) => {
            const isSelected = !!selectedList.find((item) => item.canopyAssetId === params?.row?.canopyAssetId);

            return (
              <Checkbox checked={isSelected} onClick={() => handleSelect(params?.row?.canopyAssetId, isSelected)} />
            );
          }
        },
        ...common
      ]
    : common;

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-asset-realEstate');
  
  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('canopy-asset-realEstate');

  const getRowId = useCallback((row) => row?.canopyAssetId, []);

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      getRowId={getRowId}
      columns={columns}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
});

export default RETable;
