import React, {useCallback, useState, useEffect} from 'react';
import {useSnackbar} from 'notistack';
// mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Stack,
  Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {DataGrid} from '@mui/x-data-grid';
// redux
import {useDispatch, useSelector} from 'src/redux/store';
import {getAllInsurance} from 'src/redux/slices/insurance';
import {getAdvisorInsurance} from 'src/redux/slices/client';
// api
import {carrierPull} from 'src/api/canopy';
import {saveCanopyInsurance} from 'src/api/canopy';
import {findDuplicatedLevel} from './utils';
// hook
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

// constants
import {PULL_STATUS} from 'src/constants/canopy';
// components
import PolicyTable from './CanopyPolicyTable';
import ClaimTable from './CanopyClaimTable';
import RETable from './canopyAssetTable/RETable';
import AUTable from './canopyAssetTable/AUTable';
import CanopyModal from './CanopyModal';
import CanopyVerifyOption from './CanopyVerifyOption';
import CanopyVerifyCode from './CanopyVerifyCode';
import ConfirmDialog from './ConfirmDialog';
// ----------------------------------------------------------------------
const DELAY = 1000;
const MAX_RETRY = 50;
// ----------------------------------------------------------------------
function DisplayInsuranceList({showAll = true, handleReset = () => {}, handleClose = () => {}}) {
  const {user} = useAuth();
  const isAdvisor = user.role === 'Advisor';

  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {clientFlowData, onChangeClientFlowData} = useSettings();
  const {insurance} = useSelector((state) => state.insurance);
  const {assets: opticusAssets} = useSelector((state) => state.asset);
  // NOTE: Advisor flow
  const {list} = useSelector((state) => state.client);
  const selectedClient = list.find((client) => client.show);
  // NOTE: Client flow with Family group data
  const {members} = useSelector((state) => state.familyGroup);
  const smCounts = members.filter((m) => m.show).length; // NOTE: Selected Family Member counts

  /**
   * pullInfo {pullId, pullJwt}
   */
  const [pullInfo, setPullInfo] = useState(null);
  const [provider, setProvider] = useState(null);

  const [insuranceList, setInsuranceList] = useState([]);
  const [selectedList, setSelectedList] = useState([]); // Policy

  const [assets, setAssets] = useState([]);
  const [selectedAssetList, setSelectedAssetList] = useState([]); // Assets

  const [claims, setClaims] = useState([]);
  const [selectedClaimList, setSelectedClaimList] = useState([]); // Claims

  const [drivers, setDrivers] = useState([]);
  const [drivingRecords, setDrivingRecords] = useState([]);
  const [pullResponse, setPullResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleSubmit = () => {
    if (
      selectedList.some((item) => !!item.opticusPolicyId) ||
      selectedAssetList.some((item) => !!item.opticusAssetId) ||
      selectedClaimList.some((item) => !!item.opticusClaimId)
    ) {
      setShowConfirmModal(true);
    } else {
      handleCannopySubmit(selectedList, selectedAssetList, selectedClaimList);
    }
  };

  const handleCannopySubmit = async (policyList = [], assetList = [], claimList = []) => {
    try {
      setLoading(true);
      const payload = {
        pullId: pullInfo.pullId,
        policy: policyList,
        assets: assetList,
        claims: claimList,
        clientId: selectedClient?.id
      };

      const res = await saveCanopyInsurance(payload);
      if (!!res && !!res?.result) {
        enqueueSnackbar('Public data import success', {variant: 'success'});
        // refresh insurances
        if (isAdvisor || smCounts > 0) {
          dispatch(getAdvisorInsurance(!isAdvisor));
        }
        if (!isAdvisor) {
          dispatch(getAllInsurance());
        }
        // NOTE: Update client data for advisor new client flow
        // NOTE: Add buffId to manipulate them on new client UI

        if (isAdvisor && clientFlowData.client && selectedClient.email === clientFlowData.client.email) {
          const newFormData = {
            ...clientFlowData.formData,
            insurance: res.data?.policy.map((item) => ({...item, buffId: item.id})),
            assets: {
              ...clientFlowData.formData.assets,
              realEstate: res.data?.assets
                .filter((item) => item.type === 'realEstate')
                .map((item) => ({...item, buffId: item.id})),
              automobile: res.data?.assets
                .filter((item) => item.type === 'automobile')
                .map((item) => ({...item, buffId: item.id}))
            }
          };
          onChangeClientFlowData({...clientFlowData, formData: newFormData});
        }
        handleClose();
      } else {
        enqueueSnackbar('Public data import error.', {variant: 'error'});
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(err.message || 'Public data import error.', {variant: 'error'});
    }
  };

  const handlePullInfoChange = (data) => {
    /**
     * data: {token: {pullId, pullJwt}, provider: {}}
     */
    setProvider(data.provider);
    setPullInfo(data.token);
  };

  const handleNext = () => {
    startCanopyPull(1);
  };

  const startCanopyPull = useCallback(
    async (tryCount) => {
      setLoading(true);
      try {
        const res = await carrierPull(pullInfo);
        const {status: pullStatus, policies, claims, assets, drivingRecords, drivers} = res.data || {};
        setPullResponse(res.data);
        console.log({canopy: res.data, tryCount});

        if (pullStatus === 'SUCCESS') {
          // Finding duplicated policies
          const modifiedPolicies = policies.map((p) => {
            const {data: dupPolicy} = findDuplicatedLevel(p, 'policy', insurance);
            return {
              ...p,
              isDuplicated: !!dupPolicy,
              opticusPolicyId: dupPolicy ? dupPolicy.id : ''
            };
          });
          // Finding duplicated assets
          const modifiedAssets = assets.map((p) => {
            const {data: dupAsset, level} = findDuplicatedLevel(p, 'asset', insurance, opticusAssets);
            return {
              ...p,
              dupLevel: level,
              opticusAssetId: dupAsset ? dupAsset.id : ''
            };
          });
          // Finding duplicated claims
          const modifiedClaims = claims.map((p) => {
            const {data: dupClaim} = findDuplicatedLevel(p, 'claim', insurance);
            return {
              ...p,
              isDuplicated: !!dupClaim,
              opticusClaimId: dupClaim ? dupClaim.id : ''
            };
          });

          setInsuranceList(modifiedPolicies);
          setAssets(modifiedAssets || []);
          setClaims(modifiedClaims || []);
          setDrivingRecords(drivingRecords || []);
          setDrivers(drivers || []);
          enqueueSnackbar('Public data fetch successful.', {variant: 'success'});
        } else if (
          [
            PULL_STATUS.IDENTITY_VERIFICATION_OPTIONS,
            PULL_STATUS.IDENTITY_VERIFICATION,
            PULL_STATUS.NOT_AUTHENTICATED,
            PULL_STATUS.INTERNAL_ERROR,
            PULL_STATUS.PROVIDER_ERROR
          ].includes(pullStatus)
        ) {
          setLoading(false);
        } else if (tryCount <= MAX_RETRY) {
          await new Promise((resolve) => setTimeout(resolve, DELAY));
          return await startCanopyPull(tryCount + 1);
        } else {
          // Max retry limit reached
          setLoading(false);
          setPullResponse({status: 'TIME_OUT'});
        }
      } catch (err) {
        enqueueSnackbar(err.message || 'Public data fetch error.', {variant: 'error'});
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [pullInfo]
  );

  useEffect(() => {
    if (!!pullInfo) {
      startCanopyPull(1);
    }

    // estlint-desable-next-line react-hooks/exhaustive-deps
  }, [pullInfo, startCanopyPull]);

  // Handle Policy Select
  const handleSelect = (id, isSelected) => {
    let updatedList = [...selectedList];
    let updatedAssetList = [...selectedAssetList];
    let updatedClaimList = [...selectedClaimList];

    if (isSelected) {
      updatedList = updatedList.filter((item) => item.canopyPolicyId !== id);

      // Filter out assets and claims which are related to unselected policies
      updatedAssetList = selectedAssetList.filter((item) =>
        updatedList.some((pol) => pol.canopyPolicyId === item.canopyPolicyId)
      );
      updatedClaimList = selectedClaimList.filter((item) =>
        updatedList.some((pol) => pol.canopyPolicyId === item.canopyPolicyId)
      );
    } else {
      const selectedPolicy = insuranceList.find((item) => item.policyId === id);
      if (selectedPolicy) {
        updatedList = [
          ...updatedList,
          {
            canopyPolicyId: selectedPolicy.policyId,
            policyType: selectedPolicy.type,
            opticusPolicyId: selectedPolicy.opticusPolicyId
          }
        ];

        // Select new assets, claims which are related to last selected policy
        const newAssetList = assets.filter((item) => selectedPolicy.policyId === item.canopyPolicyId);
        const newClaimList = claims.filter((item) => selectedPolicy.policyId === item.canopyPolicyId);
        updatedAssetList = [...selectedAssetList, ...newAssetList];
        updatedClaimList = [...selectedClaimList, ...newClaimList];
      }
    }
    setSelectedList(updatedList);
    setSelectedAssetList(updatedAssetList);
    setSelectedClaimList(updatedClaimList);
  };

  // Handle Policy All Select
  const handleSelectAll = () => {
    const allPolicies = insuranceList.map((item) => ({
      canopyPolicyId: item.policyId,
      policyType: item.type,
      opticusPolicyId: item.opticusPolicyId
    }));
    // Select new assets, claims which are related to last selected policy
    const newAssetList = assets.filter((item) => allPolicies.some((pol) => pol.canopyPolicyId === item.canopyPolicyId));
    const newClaimList = claims.filter((item) => allPolicies.some((pol) => pol.canopyPolicyId === item.canopyPolicyId));

    setSelectedList(allPolicies);
    setSelectedAssetList(newAssetList);
    setSelectedClaimList(newClaimList);
  };

  // Handle Asset select
  const handleAssetSelect = (id, isSelected) => {
    let updatedList = [...selectedAssetList];
    if (isSelected) {
      updatedList = updatedList.filter((item) => item.canopyAssetId !== id);
    } else {
      const selectedAsset = assets.find((item) => item.canopyAssetId === id);
      if (selectedAsset)
        updatedList = [
          ...updatedList,
          {
            canopyPolicyId: selectedAsset.canopyPolicyId,
            canopyAssetId: selectedAsset.canopyAssetId,
            assetType: selectedAsset.type,
            opticusAssetId: selectedAsset.opticusAssetId
          }
        ];
    }

    setSelectedAssetList(updatedList);
  };

  // Handle Claim select
  const handleClaimSelect = (id, isSelected) => {
    let updatedList = [...selectedClaimList];
    if (isSelected) {
      updatedList = updatedList.filter((item) => item.canopyClaimId !== id);
    } else {
      const selectedClaim = claims.find((item) => item.canopyClaimId === id);
      if (selectedClaim)
        updatedList = [
          ...updatedList,
          {
            canopyClaimId: selectedClaim.canopyClaimId,
            canopyPolicyId: selectedClaim.canopyPolicyId,
            opticusClaimId: selectedClaim.opticusClaimId
          }
        ];
    }

    setSelectedClaimList(updatedList);
  };

  const getLabel = () => {
    switch (pullResponse?.status) {
      case PULL_STATUS.STARTED:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Started...
          </Typography>
        );
      case PULL_STATUS.QUEUED:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Pull request queued...
          </Typography>
        );
      case PULL_STATUS.PROCESSING:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Pull request processing...
          </Typography>
        );
      case PULL_STATUS.IDENTITY_VERIFICATION_OPTIONS:
      case PULL_STATUS.IDENTITY_VERIFICATION:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Identity verification...
          </Typography>
        );
      case PULL_STATUS.GETTING_CONSUMERS:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Getting consumers...
          </Typography>
        );
      case PULL_STATUS.PULLING_DATA:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Pulling data...
          </Typography>
        );
      case PULL_STATUS.NOT_AUTHENTICATED:
        return (
          <>
            <Typography variant="h6" style={{opacity: 0.6, textAlign: 'center'}}>
              Authentication Error
            </Typography>
            <Typography>Please click back button and try again</Typography>
          </>
        );
      case PULL_STATUS.INTERNAL_ERROR:
      case PULL_STATUS.PROVIDER_ERROR:
        return (
          <>
            <Typography variant="h6" style={{opacity: 0.6, textAlign: 'center'}}>
              Third Party Service Error
            </Typography>
            <Typography>Please click back button and try again</Typography>
          </>
        );
      case PULL_STATUS.SUCCESS:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Success
          </Typography>
        );
      case 'TIME_OUT':
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Timeout Error
          </Typography>
        );
      default:
        return (
          <Typography variant="h6" style={{opacity: 0.8}}>
            Initializing...
          </Typography>
        );
    }
  };

  const showCanopyPolicyData = pullResponse?.status === PULL_STATUS.SUCCESS;
  const showVerifyOptionDialog = pullResponse?.status === PULL_STATUS.IDENTITY_VERIFICATION_OPTIONS;
  const showVerifyCodeDialog = pullResponse?.status === PULL_STATUS.IDENTITY_VERIFICATION;

  // Available claims
  const availableClaims = claims.filter((item) =>
    selectedList.some((pol) => pol.canopyPolicyId === item.canopyPolicyId)
  );

  // Available assets
  const availableAssets = assets.filter((item) =>
    selectedList.some((pol) => pol.canopyPolicyId === item.canopyPolicyId)
  );
  const canopyREAssets = availableAssets.filter((item) => item.type === 'realEstate'); // Real Estate Assets
  const canopyAUAssets = availableAssets.filter((item) => item.type === 'automobile'); // Automobile Assets

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>Add New Insurance from Carrier</DialogTitle>
        <Divider />
        <DialogContent>
          {!pullInfo && <CanopyModal isOpen={true} handleReset={handleReset} setPullInfo={handlePullInfoChange} />}
          {!!pullInfo &&
            (showCanopyPolicyData ? (
              <Stack>
                <Stack spacing={2}>
                  {!!insuranceList?.length ? (
                    <>
                      <Card sx={{boxShadow: showAll ? null : 'none'}}>
                        <CardContent>
                          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={3}>
                            <Stack>
                              <Typography variant="h5">INSURANCE LIST</Typography>
                              <Typography variant="body2">Select from the list</Typography>
                            </Stack>
                            <Button variant="contained" onClick={handleSelectAll}>
                              Select All
                            </Button>
                          </Stack>
                          <PolicyTable handleSelect={handleSelect} data={insuranceList} selectedList={selectedList} />
                        </CardContent>
                      </Card>
                      {!!assets?.length && showAll && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5">ASSETS</Typography>
                            {!!canopyREAssets.length && (
                              <>
                                <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
                                  Real Estate
                                </Typography>
                                <RETable
                                  data={canopyREAssets}
                                  selectedList={selectedAssetList}
                                  handleSelect={handleAssetSelect}
                                />
                              </>
                            )}
                            {!!canopyAUAssets.length && (
                              <>
                                <Typography variant="h6" sx={{color: (theme) => theme.palette.grey[500]}}>
                                  Automobile
                                </Typography>
                                <AUTable
                                  data={canopyAUAssets}
                                  selectedList={selectedAssetList}
                                  handleSelect={handleAssetSelect}
                                />
                              </>
                            )}
                          </CardContent>
                        </Card>
                      )}
                      {!!availableClaims.length && showAll && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5">CLAIMS</Typography>
                            <ClaimTable
                              data={availableClaims}
                              insuranceList={insuranceList}
                              selectedList={selectedClaimList}
                              handleSelect={handleClaimSelect}
                            />
                          </CardContent>
                        </Card>
                      )}
                      {!!drivers?.length && showAll && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5">DRIVERS</Typography>
                            <DataGridDrivers data={drivers} />
                          </CardContent>
                        </Card>
                      )}
                      {!!drivingRecords?.length && showAll && (
                        <Card>
                          <CardContent>
                            <Typography variant="h5">DRIVING RECORDS</Typography>
                            <DataGridDriving data={drivingRecords} drivers={drivers} />
                          </CardContent>
                        </Card>
                      )}
                    </>
                  ) : (
                    <Typography> No public records imported.</Typography>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '260px'}}>
                <Stack direction="row" spacing={1}>
                  <Box>{getLabel() || 'Loading carrier data...'}</Box>
                  {loading && <CircularProgress />}
                </Stack>
              </Box>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset} color="inherit">
            Back
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            disabled={!selectedList.length}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {showConfirmModal && (
        <ConfirmDialog
          loading={loading}
          policyData={insuranceList}
          assetData={assets}
          claimData={claims}
          selectedList={selectedList}
          selectedAssetList={selectedAssetList}
          selectedClaimList={selectedClaimList}
          handleClose={() => setShowConfirmModal(false)}
          handleConfirm={handleCannopySubmit}
        />
      )}
      {showVerifyOptionDialog && (
        <CanopyVerifyOption pullInfo={pullInfo} options={pullResponse?.mfa_options || {}} handleNext={handleNext} />
      )}
      {showVerifyCodeDialog && (
        <CanopyVerifyCode pullInfo={pullInfo} data={pullResponse || {}} handleNext={handleNext} provider={provider} />
      )}
    </>
  );
}

export default React.memo(DisplayInsuranceList);

// ----------------------------------------------------------------------

const DataGridDriving = React.memo(({data = [], drivers = []}) => {
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: useCallback((params) => {
        const date = params.row.date;
        return <Typography variant="body2">{new Date(date).toLocaleDateString()}</Typography>;
      }, [])
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 280
    },
    {
      field: 'fault',
      headerName: 'Fault',
      width: 120
    },
    {
      field: 'driverId',
      headerName: 'Driver Name',
      width: 220,
      renderCell: useCallback((params) => {
        const driver = drivers?.find?.((d) => d?.id === params.row.driverId)?.name || '';
        return <Typography variant="body2">{driver}</Typography>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    }
  ];

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-driving');

  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('canopy-driving');

  const getRowId = useCallback((row) => {
    return `${row?.driverId}-${row?.date}-${row?.type}`;
  }, []);

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      getRowId={getRowId}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
});

const DataGridDrivers = React.memo(({data = []}) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 220
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date Of Birth',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: useCallback((params) => {
        const date = params.row.dateOfBirth;
        return <Typography variant="body2">{!!date ? new Date(date).toLocaleDateString() : ''}</Typography>;
      }, [])
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 80
    },
    {
      field: 'driversLicense',
      headerName: 'Drivers License',
      width: 120
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      width: 120
    },
    {
      field: 'maritalStatus',
      headerName: 'Marital Status',
      width: 120
    }
  ];

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('canopy-driver');
  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('canopy-driver');

  const getRowId = useCallback((row) => {
    return `${row?.driversLicense}-${row?.dateOfBirth}-${row?.name}`;
  }, []);

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={data}
      getRowId={getRowId}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
    />
  );
});
