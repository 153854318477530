import {Icon} from '@iconify/react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// material
import {useTheme} from '@mui/material/styles';
import {Stack, Box} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings'; // Import sorting hook
// util
import {fCurrency} from 'src/utils/formatNumber';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import UserAvatar from 'src/components/UserAvatar';
// constants
import {getAssetStatusLabel} from 'src/constants/dropdown';
import {getAssetLabel} from 'src/constants/ledger';
// ----------------------------------------------------------------------

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getAssetStatusLabel(key)}
    </Label>
  );
}

// ----------------------------------------------------------------------

export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params.row.user} />;
    }
  };

  const columns = [
    {
      field: 'nickName',
      headerName: 'Name',
      width: 160
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 160,
      renderCell: (params) => getAssetLabel(params.row.type)
    },
    {
      field: 'marketValue',
      type: 'number',
      headerName: 'Market Value',
      width: 160,
      renderCell: (params) => {
        const price = params.row.marketValue;
        return fCurrency(price);
      }
    },
    {
      field: 'assetStatus',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        const status = params.getValue(params.id, 'assetStatus');
        return RenderStatus(status);
      }
    },
    {
      field: 'insured',
      type: 'boolean',
      headerName: 'Insured',
      width: 120,
      renderCell: (params) => {
        const insured = params.getValue(params.id, 'insured');
        return (
          <Stack alignItems="center" sx={{width: 1, textAlign: 'center'}}>
            {insured ? (
              <Box component={Icon} icon={checkmarkCircle2Fill} sx={{width: 20, height: 20, color: 'primary.main'}} />
            ) : (
              '-'
            )}
          </Stack>
        );
      }
    },
    {
      field: 'insuredValue',
      type: 'number',
      headerName: 'Insured Value',
      width: 160,
      renderCell: (params) => {
        const price = params.row.insuredValue;
        return fCurrency(price);
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 100
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('generalAssets');

  const {useColumnSortState} = useColumnSortSettings(); // Use sorting hook
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('generalAssets'); // Provide unique identifier

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (user.role === 'Advisor' || memberSelected?.length > 0) {
      const route = `${PATH_DASHBOARD.root}/asset/${params.row.type}/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/asset/${params.row.type}/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Add sort model
      onSortModelChange={setColumnSortSettings} // Handle sort changes
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
