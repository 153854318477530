import {Icon} from '@iconify/react';
import {useNavigate, useLocation} from 'react-router-dom';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// material
import {useTheme} from '@mui/material/styles';
import {Stack, Typography, Box} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

// util
import {fCurrency} from 'src/utils/formatNumber';
import {fDate} from 'src/utils/formatTime';
import {getFormattedAddress} from 'src/utils/stringFormat';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import UserAvatar from 'src/components/UserAvatar';
// constants
import {getAssetStatusLabel, getCollectionClassLabel} from 'src/constants/dropdown';
import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getAssetStatusLabel(key)}
    </Label>
  );
}

// ----------------------------------------------------------------------
export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);
  const isAdvisor = user.role === 'Advisor';

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  const columns = [
    {
      field: 'nickName',
      headerName: 'Name',
      width: 180
    },
    {
      field: 'class',
      headerName: 'Class',
      width: 160,
      renderCell: (params) => {
        const classNm = params.getValue(params.id, 'class');
        return (
          <Typography variant="body2" noWrap>
            {getCollectionClassLabel(classNm)}
          </Typography>
        );
      }
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 180
    },
    {
      field: 'collectionAddress',
      headerName: 'Address',
      width: 200,
      renderCell: ({value: address}) => getFormattedAddress(address)
    },
    {
      field: 'loanAddress',
      headerName: 'Loan Address',
      width: 200,
      renderCell: ({value: address}) => getFormattedAddress(address)
    },
    {
      field: 'replacementValue',
      type: 'number',
      headerName: 'Replacement Value',
      width: 160,
      renderCell: (params) => {
        const price = params.row.replacementValue;
        return fCurrency(price);
      }
    },
    {
      field: 'purchasePrice',
      type: 'number',
      headerName: 'Purchase Price',
      width: 160,
      renderCell: (params) => {
        const price = params.row.purchasePrice;
        return fCurrency(price);
      }
    },
    {
      field: 'purDate',
      headerName: 'Purchase Date',
      width: 200,
      renderCell: (params) => {
        const date = params.row.purDate;
        return fDate(date);
      }
    },
    {
      field: 'assetStatus',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        const status = params.getValue(params.id, 'assetStatus');
        return RenderStatus(status);
      }
    },
    {
      field: 'insured',
      type: 'boolean',
      headerName: 'Insured',
      width: 120,
      renderCell: (params) => {
        const insured = params.getValue(params.id, 'insured');
        return (
          <Stack alignItems="center" sx={{width: 1, textAlign: 'center'}}>
            {insured ? (
              <Box component={Icon} icon={checkmarkCircle2Fill} sx={{width: 20, height: 20, color: 'primary.main'}} />
            ) : (
              '-'
            )}
          </Stack>
        );
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 100
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('collectionAssets');

  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('collectionAssets');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (isAdvisor || memberSelected?.length > 0) {
      const route = `${PATH_DASHBOARD.root}/asset/collection/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/asset/collection/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
