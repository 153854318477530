import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Stack, Button, Drawer, Tooltip, Typography, CardActionArea } from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
// redux
import { useDispatch } from 'src/redux/store';
import { useSelector } from 'src/redux/store';
import { getAllAssets } from 'src/redux/slices/asset';
import { getAllInsurance } from 'src/redux/slices/insurance';
import { getOrgs } from 'src/redux/slices/organization';
import { getContacts } from 'src/redux/slices/contact';
import { getEvents } from 'src/redux/slices/calendar';
import {
  getAdvisorContacts,
  getAdvisorAssets,
  getAdvisorInsurance,
  getAdvisorOrgs,
  getAdvisorEvent
} from 'src/redux/slices/client';
import { getAllFamilyGroups } from 'src/redux/slices/familyGroup';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
// components
import { DocIllustration } from 'src/assets';
import Logo from 'src/components/Logo';
import MyAvatar from 'src/components/MyAvatar';
import Scrollbar from 'src/components/Scrollbar';
import NavSection from 'src/components/NavSection';
import { MHidden } from 'src/components/@material-extend';
import LogoWithName from 'src/components/LogoWithName';
import ClientPopover from 'src/components/advisor/ClientPopover';
import MemberPopover from 'src/components/client/MemberPopover';
import sidebarConfig from './SidebarConfig';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { members } = useSelector((state) => state.familyGroup);
  const isAdvisor = user && user.role === 'Advisor';
  const hasFM = !!members.length; // NOTE: Client dash with Family Group

  const navigate = useNavigate();
  const {themeExpand} = useSettings();

  const { initiated: asIni } = useSelector((state) => state.asset);
  const { initiated: cIni } = useSelector((state) => state.contact);
  const { initiated: poIni } = useSelector((state) => state.insurance);
  const { initiated: orIni } = useSelector((state) => state.organization);
  const { initiated: evIni } = useSelector((state) => state.calendar);
  const { adCoInitiated, adAsInitiated, adPoInitiated, adOrInitiated } = useSelector((state) => state.client);
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();
  const [expandAll, setExpandAll] = useState(themeExpand); // New state for expanding/collapsing all

  useEffect(() =>{
    setExpandAll(themeExpand);
  },[themeExpand])
  


  useEffect(() => {
    if (!cIni) dispatch(getContacts());
    if (!adCoInitiated && (isAdvisor || hasFM)) dispatch(getAdvisorContacts(!isAdvisor)); // NOTE: Call advisor api for advisor and client with Family Group
  }, [dispatch, cIni, adCoInitiated, isAdvisor, hasFM]);

  useEffect(() => {
    const getAssetData = () => {
      if (!isAdvisor && !asIni) {
        dispatch(getAllAssets());
      }
      if (!adAsInitiated && (isAdvisor || hasFM)) dispatch(getAdvisorAssets(!isAdvisor));
    };

    getAssetData();
  }, [dispatch, asIni, adAsInitiated, isAdvisor, hasFM]);

  useEffect(() => {
    const getInsuranceData = () => {
      if (!isAdvisor && !poIni) {
        dispatch(getAllInsurance());
      }
      if (!adPoInitiated && (isAdvisor || hasFM)) dispatch(getAdvisorInsurance(!isAdvisor));
    };

    getInsuranceData();
  }, [dispatch, poIni, adPoInitiated, isAdvisor, hasFM]);

  useEffect(() => {
    if (!isAdvisor && !orIni) dispatch(getOrgs());
    if (!adOrInitiated && (isAdvisor || hasFM)) dispatch(getAdvisorOrgs(!isAdvisor));
  }, [dispatch, orIni, adOrInitiated, isAdvisor, hasFM]);

  useEffect(() => {
    if (!isAdvisor && !evIni) dispatch(getEvents());
    if (isAdvisor || hasFM) dispatch(getAdvisorEvent(!isAdvisor));
  }, [dispatch, evIni, isAdvisor, hasFM]);

  useEffect(() => {
    dispatch(getAllFamilyGroups(isAdvisor, user.userId));
  }, [dispatch, isAdvisor, user]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {isCollapse ? <Logo /> : <LogoWithName />}
          </Box>
          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>

        </Stack>
        {isCollapse ? (
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <>
            <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user}>
              <AccountStyle>
                <MyAvatar />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {user?.role}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
            {isAdvisor ? <ClientPopover /> : <MemberPopover />}
          </>
        )}
      </Stack>
 
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} expandAll={expandAll} />
      <Box sx={{ flexGrow: 1 }} />
      {!isCollapse && (
        <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}>
          <DocIllustration sx={{ width: 1 }} />
          <div>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Need help?
              <br /> Please check our docs
            </Typography>
          </div>
          <Button onClick={() => navigate(PATH_PAGE.faqs)} variant="contained">
            Documentation
          </Button>
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
