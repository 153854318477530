import {useNavigate, useLocation} from 'react-router-dom';
// material
import {useTheme} from '@mui/material/styles';
import {Typography} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
// hooks
import useAuth from 'src/hooks/useAuth';
import useColumnSettings from 'src/hooks/useColumnSettings';
import useColumnSortSettings from 'src/hooks/useColumnSortSettings';

// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// util
import {formatPhoneNumber, getFormattedAddress} from 'src/utils/stringFormat';
// components
import Label from 'src/components/Label';
import UserAvatar from 'src/components/UserAvatar';
// constants
import {EN_TYPE, getOrgStatusLabel} from 'src/constants/dropdown';
import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'success' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getOrgStatusLabel(key)}
    </Label>
  );
}

// ----------------------------------------------------------------------
export default function DataGridCustom({data}) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const rows = [...data];

  const {members} = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const userObj = {
    field: 'user',
    headerName: 'User',
    width: 80,
    renderCell: (params) => {
      return <UserAvatar userId={params['row'].user} />;
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150
    },
    {
      field: 'entitiesType',
      headerName: 'Type',
      width: 150,
      renderCell: ({value: entitiesType}) => EN_TYPE?.find?.((r) => r?.value === entitiesType)?.label || ''
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
      renderCell: ({value: address}) => getFormattedAddress(address)
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 160,
      renderCell: (params) => {
        const website = params.getValue(params.id, 'website');
        return (
          <Typography variant="body2" sx={{textDecoration: 'underline'}} noWrap>
            {website}
          </Typography>
        );
      }
    },
    {
      field: 'primaryPhone',
      headerName: 'Phone',
      width: 160,
      renderCell: (params) => {
        const primaryPhone = params.getValue(params.id, 'primaryPhone');
        return (
          <Typography variant="body2" sx={{textDecoration: 'underline'}} noWrap>
            {formatPhoneNumber(primaryPhone)}
          </Typography>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        const status = params.row.status;
        return RenderStatus(status);
      }
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 240
    }
  ];

  if (user.role === 'Advisor') columns.splice(0, 0, userObj);

  const {useColumnState} = useColumnSettings();
  const [columnSettings, setColumnSettings] = useColumnState('entitiesOrg');
  const { useColumnSortState } = useColumnSortSettings();
  const [columnSortSettings, setColumnSortSettings] = useColumnSortState('entitiesOrg');

  const handleRowClick = (params) => {
    const selectedID = params.row.id;

    if (user.role === 'Advisor' || memberSelected.length > 0) {
      const route = `${PATH_DASHBOARD.root}/org/entity/${selectedID}/${params.row.user}`;
      navigate(route, {state});
    } else {
      const route = `${PATH_DASHBOARD.root}/org/entity/${selectedID}`;
      navigate(route, {state});
    }
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection={false}
      disableSelectionOnClick
      disableColumnMenu
      rows={rows}
      columns={columns}
      columnVisibilityModel={columnSettings}
      onColumnVisibilityModelChange={setColumnSettings}
      sortModel={Array.isArray(columnSortSettings) ? columnSortSettings : []} // Ensure it's an array
      onSortModelChange={setColumnSortSettings}
      onRowClick={handleRowClick}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5]}
      components={{
        Toolbar: GridToolbar
      }}
    />
  );
}
