import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const initialState = {};

const ColumnSortSettingsContext = createContext(initialState);

ColumnSortSettingsProvider.propTypes = {
  children: PropTypes.node
};

function ColumnSortSettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('columnSortSettings', initialState);

  const onChangeSettings = (tableKey, toChange) => {
    setSettings({
      ...settings,
      [tableKey]: [...toChange]
    });
  };

  const getColumnSortSettings = (tableKey) => settings[tableKey] || [];

  const useColumnSortState = (tableKey) => {
    useEffect(() => {
      // Ensure settings[tableKey] is initialized as an empty array if undefined
      setSettings((prevSettings) => {
        if (!Array.isArray(prevSettings?.[tableKey])) {
          return { ...prevSettings, [tableKey]: [] };
        }
        return prevSettings;
      });
    }, [tableKey]);

    const columnSortState = getColumnSortSettings(tableKey); // Always returns an array
    const setColumnSortState = (toChange) => onChangeSettings(tableKey, toChange);
    return [columnSortState, setColumnSortState];
  };

  return (
    <ColumnSortSettingsContext.Provider
      value={{
        getColumnSortSettings,
        onChangeSettings,
        useColumnSortState
      }}
    >
      {children}
    </ColumnSortSettingsContext.Provider>
  );
}

export { ColumnSortSettingsProvider, ColumnSortSettingsContext };
